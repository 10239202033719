import { injectable } from "inversify";
import * as moment from 'moment';
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./profil-view.html";
import rideModal from "./ride-modal.html";
import deleteRideModal from "./ride-delete-modal.html";
import { RidesApiClient } from "../ts/clients/rides-api-client";
import { FullscreenLoader } from "./fullscreen-loader";
import { Notyf } from "notyf";
import { UmbracoApiClient } from "../ts/clients/umbraco-api-client";
import "jquery-validation-unobtrusive";
import { createPopper } from '@popperjs/core';
import { UserApiClient } from "../ts/clients/users-api-client";
import { BikerInfoFormRequest } from "../ts/models/biker-info-form-request";
import { TeamApiClient } from "../ts/clients/team-api-client";
import friendInvitationWidget from "./widget-friend-invitation.html";
import { FriendInvitationApiClient } from "../ts/clients/friend-invitation-api-client";
import teamWidget from "./widget-ekipa.html";
import addTeamModal from "./add-team-modal.html";
import deleteTeamModal from "./delete-team-modal.html";
import editTeamModal from "./edit-team-modal.html";
import surveyModal from "./survey-modal.html";
import leaveTeamModal from "./leave-team-modal.html";

import { ConfirmTeamMemberRequest } from "../ts/models/confirm-team-member-request";
import { RegionEmployerManager } from "../ts/utilities/region-employer-manager";
import SimpleBar from "simplebar";
import { ExistingBikerInfoFormRequest } from "../ts/models/biker-info-existimg-form-request";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import { CurrentUserInfo } from "../ts/models/current-user-info";

interface SurveyViewModel {
    surveyStep0?: boolean;
    surveyStep1?: boolean;
    surveyStep2?: boolean;
    surveyStep3?: boolean;
    surveyStep4?: boolean;
}

let currentStep: keyof SurveyViewModel = 'surveyStep0';
let surveyViewModel: SurveyViewModel = {
    surveyStep0: true,
    surveyStep1: false,
    surveyStep2: false,
    surveyStep3: false,
    surveyStep4: false
};

@injectable()
export class ProfilView {
    private _ridesApiClient: RidesApiClient;
    private _umbracoClient: UmbracoApiClient;
    private _userApiClient: UserApiClient;
    private _teamApiClient: TeamApiClient;
    private _friendInvitationApiClient: FriendInvitationApiClient;
    private _translationService: TranslationService;
    private _translations: any;
    private _rides: any;
    private _userStats: any;
    private _userChallengeStats: any;
    private _currentScrollPos: number;
    private _novice: any;
    private _nasvet: any;
    private _ekipa: any;
    private _teamRank: any;
    private _userNewTeamMember: boolean;
    private _ekipaJePolna: boolean;
    private _regionEmployerManager: RegionEmployerManager;
    private _currentUserAccessor: CurrentUserAccessor;
    private _currentUser?: CurrentUserInfo;
    public constructor(ridesApiClient: RidesApiClient,
        umbracoClient: UmbracoApiClient,
        userApiClient: UserApiClient,
        teamApiClient: TeamApiClient,
        friendInvitationApiClient: FriendInvitationApiClient,
        translationService: TranslationService,
        private loader: FullscreenLoader,
        private notyf: Notyf,
        currentUserAccessor: CurrentUserAccessor,) {
        this._ridesApiClient = ridesApiClient;
        this._umbracoClient = umbracoClient;
        this._userApiClient = userApiClient;
        this._teamApiClient = teamApiClient;
        this._friendInvitationApiClient = friendInvitationApiClient;
        this._translationService = translationService;
        this._translations = null;
        this._novice = null;
        this._nasvet = null;
        this._currentScrollPos = 0;
        this._ekipa = null;
        this._teamRank = [];
        this._userNewTeamMember = false;
        this._ekipaJePolna = false;
        this._regionEmployerManager = new RegionEmployerManager(this._userApiClient, this.notyf);
        this._currentUserAccessor = currentUserAccessor
        this._currentUser = undefined;
    }

    public async load(): Promise<void> {
        try {
            // Preverimo ali se je uporabnik pravkar pridružil ekipi
            this._currentUser =  await this._currentUserAccessor.getUser(true);

            this._preveriDodajanjeClanaVEkipi();
            await this._renderData();

            // var showBikerExistinghInfoForm = await this._userApiClient.showExistinhBikerInfoForm();
            var showBikerExistinghInfoForm = false;

            var showBikerInfoForm = await this._userApiClient.showBikerInfoForm();
            if (showBikerInfoForm && !showBikerExistinghInfoForm) {
                $("#bikerInfoModal").modal("show");
            }

            if (showBikerExistinghInfoForm) {
                this.initializeModal() 
                var bikerExistingInfoFormElement = $("#surveyForm");
                $.validator.unobtrusive.parse(bikerExistingInfoFormElement);
                bikerExistingInfoFormElement.validate();
                
                $(document).on('click', '#btn-cancel-survey, #btn-cancel-survey1, #btn-cancel-survey2, #btn-cancel-survey3, #btn-cancel-survey4, #btn-start-finish' , (e : any)  => {
                    closeSurvey();
                    this.cancleExistingForm(e);
                    this._regionEmployerManager._updateRegionAndEmployerModal();

                });
                $(document).on('click', '#btn-start-survey, #btn-next1, #btn-next2, #btn-next3, #right-arrow-1, #right-arrow-2, #right-arrow-3', function() {
                    if (bikerExistingInfoFormElement.valid()) {
                        moveToNextStep();
                    }
                });
                $(document).on('click', '#left-arrow-1, #left-arrow-2, #left-arrow-3, #left-arrow-4', function() {
                    moveToPreviousStep();
                });
            }

            const popcorn = document.querySelectorAll('#tooltipParent');
            const tooltip = document.querySelectorAll('#tooltip');
            if (popcorn && tooltip) {
                for (var i = 0; i < popcorn.length; i++) {
                    createPopper(popcorn[i], tooltip[i] as HTMLElement, {
                        placement: 'top-start',
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 8],
                                },
                            },
                        ],
                    });
                }
            }
            $('[data-toggle="tooltip"]').tooltip({
                placement: 'bottom'
            });
        } catch (e) {
            console.log(e);
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private async _preveriDodajanjeClanaVEkipi() {
        if (window.location.href.includes("teamId")) {
            var urlList = window.location.href.split("teamId=");
            var queryStrings = urlList[1].split("&memberId=");
            var teamId = queryStrings[0];
            var memberId = Number(queryStrings[1]);
            var data: ConfirmTeamMemberRequest = {
                teamId: teamId,
                userId: memberId
            };
            await this._teamApiClient.confirmTeamMember(data)
                .then(async (response) => {
                    if (response.success) {
                        this.notyf.success(response.message);
                        this._userNewTeamMember = true;
                    }
                    else {
                        if (response.polnaEkipa) {
                            this._ekipaJePolna = true;
                        }
                        this.notyf.error(response.message);
                    }
                })
                .catch(() => {
                    this.notyf.error("Prišlo je do napake pri dodajanju člana v ekipi.");
                });
        }
    }

    private async _renderData(): Promise<void> {
        this._currentUser =  await this._currentUserAccessor.getUser(true);
         var showBikerExistinghInfoForm = false;

        this._rides = await this._ridesApiClient.getRides();
        var noviceResponse = await this._umbracoClient.getNovice(0, 2);
        this._novice = noviceResponse.Novice;
        var nasvetiResponse = await this._umbracoClient.getNasveti(0, 1);
        if (nasvetiResponse.Nasveti && nasvetiResponse.Nasveti.length > 0) {
            this._nasvet = nasvetiResponse.Nasveti;
        }
        this._userStats = await this._ridesApiClient.getUserStats();
        this._userChallengeStats = await this._ridesApiClient.getUserChallengeStats();

        let idUserTeam = "";

        this._getTemRank(idUserTeam)

        var anketaNavad = await this._umbracoClient.getAnketoNavad();
        var znacke = await this._ridesApiClient.getBadges();
        var skupneZmageStats = await this._ridesApiClient.getSkupneZmageStats();

        // Build a view model from the API data
        this._translations = this._translationService.currentTranslations;

        const viewModel = {
            imageUrl: "../img/icon-logout.svg",
            imageKuponUrl: "../img/icon-kuponi.png",
            imageKodaUrl: "../img/koda.jpg",
            translations: this._translations,
            rides: this._rides,
            userStats: this._userStats,
            userChallengeStats: this._userChallengeStats,
            formators: this.getFormators(),
            novice: this._novice,
            nasvet: null,
            anketaNavad: anketaNavad,
            znacke: znacke,
            skupneZmage: skupneZmageStats,
            rank: this._teamRank,
            userTeamId:  idUserTeam || "",
            showRank: this._teamRank.length > 0 && this._userChallengeStats.challengeActive
        } as any;
        if (this._nasvet && this._nasvet.length > 0) {
            viewModel.nasvet = this._nasvet[0];
        }

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#main').html(html);

        this._setProgressBars(znacke);

        if(this._userChallengeStats.challengeActive)
        {
            new SimpleBar($('#rank')[0], {
                autoHide: false,
                scrollbarMinSize: 5
          });
        }
       
        await this._renderEkipa();

        if (this._userNewTeamMember) {
            // če se je uporabnik zdaj pridružil v ekipi 
            // skrolamo do ekipe in spremenimo url-ja
            var element = $("#section-ekipa")[0] as HTMLInputElement;
            element.scrollIntoView();
            var newUrl = "#/profil";
            history.pushState({}, "", newUrl);
        }

        if (this._ekipaJePolna) {
            // če se uporabnik ni pridružil v ekipi ker je polna
            // skrolamo do ekipe in spremenimo url-ja ter pokažemo obvestila
            $("#ekipa-full-alert")[0].style.display = "block";
            var element = $("#section-ekipa")[0] as HTMLInputElement;
            element.scrollIntoView();
            var newUrl = "#/profil";
            history.pushState({}, "", newUrl);
        }

        await this._renderFriendInvirationModal();
        if(this._currentUser !== undefined && this._currentUser.showUserExistingForm === false && showBikerExistinghInfoForm == false)
        {
            await this._showRegionEmployerModal();
        }

        this.initialize();

        $('#btn-share-user-score').on('click', async () => {
            $('#btn-share-user-score').css('disabled', "true");
            $('#text-share-user-score').text("Slike se prenašajo, prosimo počakajte");

            const downloadImage = async () => {
                try {
                    const imageData = await this._ridesApiClient.getImage();
                    const imageUrl = window.URL.createObjectURL(imageData);
            
                    // Create a link and trigger download without appending it to the DOM
                    const downloadLink = document.createElement('a');
                    downloadLink.href = imageUrl;
                    downloadLink.setAttribute('download', "dosezki.zip");
                    downloadLink.style.display = 'none';
                    document.body.appendChild(downloadLink);  // Temporarily add to body to initiate download
                    downloadLink.click();
                    document.body.removeChild(downloadLink);  // Remove immediately after
                    window.URL.revokeObjectURL(imageUrl);
                } catch (e) {
                    this.notyf.error("Prišlo je do napake pri prenašanju slik.");
                } finally {
                    // Re-enable the button and reset the text after the download completes
                    $('#btn-share-user-score').prop('disabled', false);
                    $('#text-share-user-score').text("Deli zmage");
                }
            };
        
            await downloadImage();  // Download Post Image
        });
    }

    private async _showRegionEmployerModal() {
        var element = $("#fullPage");
        if (element.hasClass("showRegionEmployerModal")) {
            this._regionEmployerManager._updateRegionAndEmployerModal();
        }
    }

    private async _getTemRank( idUserTeam: string)
    {
        if(this._userChallengeStats.challengeActive) {
            this._teamRank = await this._teamApiClient.getTeamRank();
            this._currentUser =  await this._currentUserAccessor.getUser(true);
          
            if( this._currentUser !== undefined && this._currentUser?.teamId !== undefined && this._currentUser?.teamId !== null)
            {
                if(this._currentUser?.teamId !== null)
                {
                    idUserTeam = this._currentUser?.teamId;
                    const team = this._teamRank.find((x : any) => x.teamId === idUserTeam);
                    if (team) {
                        team.currentUserTeam = true;
                    }
                }
            }
        }
    }

    private _setProgressBars(znacke: any) {
        $("#kolecar-50-progress-number")[0].innerHTML = znacke.odstotekKmIzziv50 + "%";
        $("#kolecar-50-progress-bar")[0].style.width = znacke.odstotekKmIzziv50 + "%"; 
        $("#kolecar-100-progress-number")[0].innerHTML = znacke.odstotekKmIzziv100 + "%";
        $("#kolecar-100-progress-bar")[0].style.width = znacke.odstotekKmIzziv100 + "%"; 
        $("#kolecar-200-progress-number")[0].innerHTML = znacke.odstotekKmIzziv200 + "%";
        $("#kolecar-200-progress-bar")[0].style.width = znacke.odstotekKmIzziv200 + "%"; 

        $("#zvezda-stalnica-progress-number")[0].innerHTML = znacke.dniZvezdaStalnica + "/5";
        var zvezdaStalnicaOdstotek = znacke.dniZvezdaStalnica / 5 * 100;
        $("#zvezda-stalnica-progress-bar")[0].style.width = zvezdaStalnicaOdstotek + "%";
        $("#zvezda-vztrajnica-progress-number")[0].innerHTML = znacke.tedniZvezdaVstrajnica + "/" + znacke.steviloTednovVIzzivu;
        if (znacke.steviloTednovVIzzivu != 0) {
            var zvezdaVztrajnicaOdstotek = znacke.tedniZvezdaVstrajnica / znacke.steviloTednovVIzzivu * 100;
            $("#zvezda-vztrajnica-progress-bar")[0].style.width = zvezdaVztrajnicaOdstotek + "%";
        }
        else {
            var zvezdaVztrajnicaOdstotek = 0;
            $("#zvezda-vztrajnica-progress-bar")[0].style.width = "0%";
        }

        $("#mini-kolecar-progress-number")[0].innerHTML = znacke.povabljenePrijateljeMini + "/2";
        var miniKolecarOdstotek = znacke.povabljenePrijateljeMini / 2 * 100;
        $("#mini-kolecar-progress-bar")[0].style.width = miniKolecarOdstotek + "%";
        $("#maxi-kolecar-progress-number")[0].innerHTML = znacke.povabljenePrijateljeMaxi + "/5";
        var maxiKolecarOdstotek = znacke.povabljenePrijateljeMaxi / 5 * 100;
        $("#maxi-kolecar-progress-bar")[0].style.width = maxiKolecarOdstotek + "%";
    }

    private async _renderEkipa() {
        this._ekipa = await this._teamApiClient.getUserTeam();
        var steviloProstihMestDo3 = [];
        let teamFull = false;
        if (this._ekipa.teamMembers != null && this._ekipa.teamMembers.length > 0) {
            var prostoDo3 = 3 - this._ekipa.teamMembers.length;
            if (prostoDo3 > 0) {
                steviloProstihMestDo3 = new Array(prostoDo3);
            }
            teamFull = this._ekipa.teamMembers.length >= 6;
        }
        const viewModel = {
            ekipa: this._ekipa,
            steviloProstihMestDo3: steviloProstihMestDo3,
            teamMembersFull: teamFull,
            formators: this.getFormators()
        } as any;

        const html = Mustache.render(teamWidget, viewModel);
        $('#section-ekipa').html(html);

        this._renderAddTeamModal();
        this._renderLeaveTeamModal();
        this._renderDeleteTeamModal();
        this._renderEditTeamModal(this._ekipa);

        var btnAddTeam = $("#btn-add-team");
        btnAddTeam.on("click", function (ev: Event) {
            ev.preventDefault();
            $("#addTeamModal").modal("show");
        });
        
        var btnLeaveTeam = $("#btn-leave-team");
        btnLeaveTeam.on("click", function (ev: Event) {
            ev.preventDefault();
            $("#leaveTeamModal").modal("show");
        });

        var btnUrediEkipo = $("#btn-uredi-ekipo");
        btnUrediEkipo.on("click",  () =>{
            $("#editTeamModal").modal("show");
            
            if(this._ekipa.avatarFilePath !== null && this._ekipa.avatarFilePath !== "")
            {
               $("#file-upload-wrapper-team").show(); 
               $('#team-logo-initials').hide(); 
    
            } else {
                $("#file-upload-wrapper-team").hide(); 
                $('#team-logo-initials').show(); 
            }
        });

        var btnDodajSokolecarja = $("#btn-dodaj-sokolecarja");
        btnDodajSokolecarja.on("click", function () {
            $("#editTeamModal").modal("show");
        });

        var btnDodajSokolecarja2 = $("#btn-dodaj-sokolecarja-2");
        btnDodajSokolecarja2.on("click", function () {
            $("#editTeamModal").modal("show");
        });
    }

    private async _renderFriendInvirationModal() {
        const html = Mustache.render(friendInvitationWidget, {});
        $("#friendInvitationModalWrap").html(html);

        var btnFriendInvitation = $("#btn-friend-invitation");
        btnFriendInvitation.on("click", function () {
            $("#friendInvitationModal").modal("show");
        });

        var btnFriendInvitation2 = $("#btn-friend-invitation-2");
        btnFriendInvitation2.on("click", function () {
            $("#friendInvitationModal").modal("show");
        });

        var friendInvitationFormElement = $(("#friendInvitationForm"));
        $.validator.unobtrusive.parse(friendInvitationFormElement);
        friendInvitationFormElement.validate();

        friendInvitationFormElement.on("submit", async (ev: Event) => {
            ev.preventDefault();
            if (friendInvitationFormElement.valid()) {
                var email = $("#friend-email").val() as string;
                await this._friendInvitationApiClient.sendFriendInvitation(email)
                    .then(async (response) => {
                        if (response.success) {
                            this.notyf.success(response.message);
                            $("#friendInvitationModal").modal("hide");
                        }
                        else {
                            this.notyf.error(response.message);
                        }
                    })
                    .catch(() => {
                        this.notyf.error("Prišlo je do napake pri pošiljanju povabila.");
                    });
            }
        });
    }

    private _renderAddTeamModal() {
        const html = Mustache.render(addTeamModal, {});
        $("#addTeamModalWrap").html(html);

        var btnAddTeamModalCancel = $("#btn-add-team-modal-cancel");
        btnAddTeamModalCancel.on("click", function (ev: Event) {
            ev.preventDefault();
            $("#addTeamModal").modal("hide");
        });

        var addTeamFormElement = $(("#addTeamForm"));
        $.validator.unobtrusive.parse(addTeamFormElement);
        addTeamFormElement.validate();
        addTeamFormElement.on("submit", async (ev: Event) => {
            ev.preventDefault();
            if (addTeamFormElement.valid()) {
                this._addTeam();
            }
        });
    }
    
    private _renderLeaveTeamModal() {
        const html = Mustache.render(leaveTeamModal, {});
        $("#leaveTeamModalWrap").html(html);

        var btnLeaveTeamModalCancel = $("#btn-leave-team-modal-close");
        btnLeaveTeamModalCancel.on("click", function (ev: Event) {
            ev.preventDefault();
            $("#leaveTeamModal").modal("hide");
        });

        var leaveTeamForm = $(("#leaveTeamForm"));
        leaveTeamForm.on("submit", async (ev: Event) => {
            ev.preventDefault();
            this._leaveTeam();
        });
    }

    private async _addTeam() {
        var teamName = $("#team-name-add-team").val() as string;
        await this._teamApiClient.addTeam(teamName)
            .then(async (response) => {
                if (response.success) {
                    this.notyf.success(response.message);
                    $("#addTeamModal").modal("hide");
                    this._renderEkipa();
                }
                else {
                    this.notyf.error(response.message);
                }
            })
            .catch(() => {
                this.notyf.error("Prišlo je do napake pri dodajanju ekipe.");
            });
    }
    
    private async _leaveTeam() {
        this._currentUser =  await this._currentUserAccessor.getUser(true);
        var userId = this._ekipa.teamMembers.filter((x: any) => x.id === this._currentUser?.id)[0].id;
        await this._teamApiClient.removeUserFromTeam(userId)
            .then(async (response) => {
                if (response.success) {
                    this.notyf.success(response.message);
                    $("#leaveTeamModal").modal("hide");
                    window.location.reload()
                }
                else {
                    this.notyf.error(response.message);
                }
            })
            .catch(() => {
                this.notyf.error("Prišlo je do napake pri brisanju člana iz ekipe.");
            });
    }

    private async _sendTeamMemberInvitation() {
        var emails = $("#emails-edit-team").val() as string;
        if (!this._emailsValidation(emails)) {
            this.notyf.error("Morate vpisati pravilen email uporabnika.");
            return;
        }
        await this._teamApiClient.sendInvitation(emails)
            .then(async (response) => {
                if (response.success) {
                    this.notyf.success(response.message);
                    
                    if(this._ekipa.avatarFilePath !== null && this._ekipa.avatarFilePath !== "")
                    {
                    $("#file-upload-wrapper-team").show(); 
                    $('#team-logo-initials').hide(); 

                    } else {
                        $("#file-upload-wrapper-team").hide(); 
                        $('#team-logo-initials').show(); 
                    }
                }
                else {
                    this.notyf.error(response.message);
    
                    if(this._ekipa.avatarFilePath !== null && this._ekipa.avatarFilePath !== "")
                    {
                    $("#file-upload-wrapper-team").show(); 
                    $('#team-logo-initials').hide(); 

                    } else {
                        $("#file-upload-wrapper-team").hide(); 
                        $('#team-logo-initials').show(); 
                    }
                }
            })
            .catch(() => {
                this.notyf.error("Prišlo je do napake pri pošiljanje povabil.");
            });
    }

    private _renderDeleteTeamModal() {
        const html = Mustache.render(deleteTeamModal, {});
        $("#deteleTeamModalWrap").html(html);

        var btnDeleteTeamCancel = $("#btn-delete-team-cancel");
        btnDeleteTeamCancel.on("click", function (ev: Event) {
            ev.preventDefault();
            $("#deleteTeamModal").modal("hide");
            $("#editTeamModal").modal("show");
        });

        var btnDeleteTeamSubmit = $("#btn-delete-team-submit");
        btnDeleteTeamSubmit.on("click", async (ev: Event) => {
            ev.preventDefault();
            await this._teamApiClient.deleteTeam()
                .then(async (response) => {
                    if (response.success) {
                        this.notyf.success(response.message);
                        $("#deleteTeamModal").modal("hide");
                        await this._renderEkipa();
                    }
                    else {
                        this.notyf.error(response.message);
                    }
                })
                .catch(() => {
                    this.notyf.error("Prišlo je do napake pri urejanju ekipe.");
                });
        });
    }

    private _renderEditTeamModal(ekipa: any) {
        const editTeamModalModel = {
            team: ekipa
        };

        const html = Mustache.render(editTeamModal, editTeamModalModel);
        $("#editTeamModalWrap").html(html);

        if(this._ekipa.avatarFilePath !== null && this._ekipa.avatarFilePath !== "")
        {
           $("#file-upload-wrapper-team").show(); 
           $('#team-logo-initials').hide(); 
        } else {
            $("#file-upload-wrapper-team").hide(); 
            $('#team-logo-initials').show(); 
        }

        //change picture 
        $('#file-delete-team').on('click', () => {
            this._ekipa.avatarFilePath = null;
            $('#file-upload-wrapper-team').hide();
            $('#team-logo-initials').show();
            (document.getElementById("profile-img-team-edit")  as any ).value = null
        })

        // Send invitation
        var editTeamFormElement = $(("#editTeamModal"));
        editTeamFormElement.on("submit", async (ev: Event) => {
            ev.preventDefault();
            await this._sendTeamMemberInvitation();
        });
        var btnSendInvitation = $("#btn-send-invitation");
        btnSendInvitation.on("click", async (ev: Event) => {
            ev.preventDefault();
            await this._sendTeamMemberInvitation();
        });

        // Razpusti ekipo
        var btnRemoveAllTeamMembers = $("#btn-remove-all-team-members");
        btnRemoveAllTeamMembers.on("click", async (ev: Event) => {
            ev.preventDefault();
            $("#editTeamModal").modal("hide");
            $("#deleteTeamModal").modal("show");            
        });

        // Save button
        var btnEditTeamSave = $("#btn-save-edit-team");
        btnEditTeamSave.on("click", async (ev: Event) => {
            ev.preventDefault();
            var fromData = new FormData();
            const fileInput: HTMLInputElement = document.getElementById("file-input-team") as HTMLInputElement;
            if(this._ekipa.avatarFilePath === null)
            {
                if (fileInput && fileInput.files) {
                    fromData.append("file", fileInput.files[0]);
                }
                await this._teamApiClient.changeAvatar(fromData).then( async(response) =>{
                    this.loader.show();
                    if (response.success) {
                        $("#editTeamModal").modal("hide");
                        await this.load().then(() => {
                            this.loader.hide()
                        });
                     }
                });
            }
            else 
            {
                $("#editTeamModal").modal("hide")
            }
        });
        // Edit button
        var btnEditTeamCancel = $("#btn-cancel-edit-team");
        btnEditTeamCancel.on("click", (ev: Event) => {
            ev.preventDefault();
            $("#editTeamModal").modal("hide");
        });

        // Remove team member
        var removeTeamMemberButtons = $(".remove-team-member");
        for (let i = 0; i < removeTeamMemberButtons.length; i++) {
            removeTeamMemberButtons[i].addEventListener("click", async (ev: any) => {
                ev.preventDefault();
                var userId = $(ev.target).data("userid");
                await this._teamApiClient.removeUserFromTeam(userId)
                    .then(async (response) => {
                        if (response.success) {
                            $("#editTeamModal").modal("hide");
                            this.notyf.success(response.message);
                            await this._renderEkipa();
                            $("#remove-team-member-alert").show();
                            $("#editTeamModal").modal("show");
                        }
                        else {
                            this.notyf.error(response.message);
                        }
                    })
                    .catch(() => {
                        this.notyf.error("Prišlo je do napake pri odstranjevanju člana ekipe.");
                    });
            });
        }

        $("#editTeamModal").on('hidden.bs.modal', function () {
            $("#remove-team-member-alert").hide();
        });

        var imageElement = document.getElementById("profile-img-team-edit") as HTMLImageElement;
        $("#file-input-team").on('change', (event) => {
            const file = (event.target as HTMLInputElement).files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    imageElement.src = event.target?.result as string;
                };
                $('#file-upload-wrapper-team').show();
                $('#team-logo-initials').hide();
                reader.readAsDataURL(file);
            }
        });
    }

    private _emailsValidation(emails: string): boolean {
        var emails = emails.replace(/\s/g, '');
        var allEmails = emails.split(";");
        for (let i = 0; i < allEmails.length; i++) {
            if (allEmails[i] != "" && !allEmails[i].match(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/)) {
                return false;
            }
        }
        return true;
    }

    private isInView(el: any) {
        var calendar = $('.calendar-scroll');
        var calendarDay = $(el);
        if (calendarDay) {
            var elementLeft = calendarDay.offset()?.left ?? 0;
            var outerWidth = calendarDay.outerWidth() ?? 0;
            var elementEnd = elementLeft + outerWidth;

            var viewportLeft = calendar.offset()?.left ?? 0;
            var viewportWidth = calendar.width() ?? 0;
            var viewportEnd = viewportLeft + viewportWidth;
            return elementEnd > viewportLeft && elementLeft < viewportEnd; 
        }              
    }

    private setCurrentMonth() {
        //var monthsInView = [];
        var firstMonth = null;
        var firstMonthCount = 0;
        var secondMonth = "";
        var secondMonthCount = 0;
        var dates = $(".calendar-day");
        for (let i = 0; i < dates.length; i++) {
            if (this.isInView(dates[i])) {
                var date = $(dates[i]).data("date");
                var dateMoment = moment(date);
                var month = dateMoment.format("MMMM");
                var year = dateMoment.format("YYYY");
                //Get month of the date
                console.log(month + " " + year);

                if (!firstMonth) {
                    firstMonth = month + " " + year;
                    firstMonthCount++;
                    continue;
                }

                if (firstMonth == month + " " + year) {
                    firstMonthCount++;
                }
                else if (!secondMonth) {
                    secondMonth = month + " " + year;
                    secondMonthCount++;
                }
                else {
                    secondMonthCount++;
                }
            }
        }

        if (firstMonth && !secondMonth) {
            $("[name=cal-month]").html(firstMonth);
        }
        if (firstMonth && secondMonth) {
            if (firstMonthCount > secondMonthCount) {
                $("[name=cal-month]").html(firstMonth);
            }
            else {
                $("[name=cal-month]").html(secondMonth);
            }
        }
    }

    private initialize() {
        var self = this;
        this._dodajValidacijskeFunkcije();

        // Initialize biker info form
        var bikerInfoFormElement = $(("#bikerInfoForm"));
        $.validator.unobtrusive.parse(bikerInfoFormElement);
        bikerInfoFormElement.validate();
        bikerInfoFormElement.on("submit", async (ev: Event) => {
            if (!bikerInfoFormElement.valid()) {
                return;
            }
            await this._addBikerInfoForm(ev, bikerInfoFormElement);
        });
        var bikerInfoFormCloseBtn = $("#btn-biker-modal-close");
        bikerInfoFormCloseBtn.on("click", function () {
            $("#bikerInfoModal").modal("hide");
        });

        // Initialize web notifications modal
        //var btnWebNotificationsYes = $("#btn-web-notifications-modal-yes");
        //btnWebNotificationsYes.on("click", async () => {
        //    await this._userApiClient.setAllowWebNotifications(true);
        //    $("#allowWebNotificationsModal").modal("hide");
        //    this.notyf.success("Uspešno shranjeno");
        //});
        //var btnWebNotificationsNo = $("#btn-web-notifications-modal-no");
        //btnWebNotificationsNo.on("click", async () => {
        //    this._cookieManager.setCookieForWebNotifications();
        //    $("#allowWebNotificationsModal").modal("hide");
        //    this.notyf.success("Uspešno shranjeno");
        //});

        // Scroll calendar to show today's date
        if (self._currentScrollPos != null && self._currentScrollPos != 0) {
            $('.calendar-scroll').scrollLeft(self._currentScrollPos);
        }
        else {
            var $todayCell = $('.calendar-date').last();
            if ($todayCell.length) {
                var scrollPos = $('.calendar-scroll').scrollLeft();
                var todayPos = $todayCell.position().left;
                var todayWidth = $todayCell.outerWidth();
                if (scrollPos != undefined && todayWidth) {
                    $('.calendar-scroll').scrollLeft(todayPos - 2 * todayWidth + 50);
                    self._currentScrollPos = $('.calendar-scroll').scrollLeft() ?? 0;
                }
            }
        }

        self.setCurrentMonth();

        // Add event listeners for arrow buttons
        $('.calendar-prev').on("click", function () {
            var scrollPos = $('.calendar-scroll').scrollLeft();
            if (scrollPos != undefined) {
                $('.calendar-scroll').scrollLeft(scrollPos - 100); // adjust the value for sliding left as needed
                self._currentScrollPos = $('.calendar-scroll').scrollLeft() ?? 0;
            };

            self.setCurrentMonth();
        });
        $('.calendar-next').on("click", function () {
            var scrollPos = $('.calendar-scroll').scrollLeft();
            if (scrollPos != undefined) {
                $('.calendar-scroll').scrollLeft(scrollPos + 100); // adjust the value for sliding right as needed
                self._currentScrollPos = $('.calendar-scroll').scrollLeft() ?? 0;
            };

            self.setCurrentMonth();
        });
        //$($todayCell).nextAll(".calendar-date").addClass("disabled");

        // Make calendar draggable
        $('.calendar-scroll').on('mousedown touchstart', function (e) {
            //e.preventDefault();
            var startX = e.pageX || (e.changedTouches && e.changedTouches[0].pageX) || 0;
            startX += this.scrollLeft;
            $(document).on('mousemove touchmove', function (e) {
                //e.preventDefault();
                var newX = e.pageX || (e.changedTouches && e.changedTouches[0].pageX) || 0;
                newX = startX - newX;
                $('.calendar-scroll').scrollLeft(newX);
            });
            $(document).on('mouseup touchend', function () {
                $(document).off('mousemove touchmove');
                $(document).off('mouseup touchend');
                self.setCurrentMonth();
            });
            self._currentScrollPos = $('.calendar-scroll').scrollLeft() ?? 0;
            
        });
        $('.calendar-scroll').on('wheel', function (e) {
            //e.preventDefault();
            var delta = (e.originalEvent as WheelEvent).deltaY;
            var scrollLeft = $('.calendar-scroll').scrollLeft();
            $('.calendar-scroll').scrollLeft(scrollLeft! + delta);
            self._currentScrollPos = $('.calendar-scroll').scrollLeft() ?? 0;

            self.setCurrentMonth();
        });

        /* Selecting date to input a ride */
        $('.calendar-day').on('mousedown touchstart', function (ev) {
            //ev.preventDefault();
            var flag = false;
            $(document).on('mousemove touchmove', function () {
                //e.preventDefault();
                flag = true;
            });
            $(document).on('mouseup touchend', function (e) {
                console.log(e);
                $(document).off('mousemove touchmove');
                $(document).off('mouseup touchend')
                if (flag) { return; }

                if ($('.calendar-scroll').hasClass('ui-draggable-dragging')) { return false; }
                if ($(ev.target).hasClass("deleteRide")) {
                    return;
                }
                if ($(ev.target).closest(".calendar-date").hasClass("disabled")) {
                    return;
                }

                var id = $(ev.currentTarget).attr("data-id") as string;
                var date = $(ev.currentTarget).attr("data-date") as string;
                var kilometers = $(ev.currentTarget).attr("data-km") as string;
                var displayDate = $(ev.currentTarget).find(".day-name").text() as string;
                displayDate = displayDate.replace(/\s/g, '')
                displayDate += ", " + $(ev.currentTarget).attr("data-displayDate") as string;

                const modalViewModel = {
                    id: id,
                    kilometers: kilometers,
                    displayDate: displayDate,
                    date
                } as any;

                const modalHtml = Mustache.render(rideModal, modalViewModel);
                $('#rideModalWrap').html(modalHtml);

                var formElement = $("#submitRideForm");
                $.validator.unobtrusive.parse(formElement);
                formElement.validate();

                formElement.on("submit", function (ev) {
                    ev.preventDefault();
                    if (!formElement.valid()) {
                        return;
                    }
                    //self.loader.show();
                    kilometers = formElement.find("#kilometers").val() as string;
                    kilometers = kilometers.trimStart();
                    kilometers = kilometers.trimEnd();
                    kilometers = kilometers.replace(',', '.');

                    if (Number(kilometers) <= 0 || Number(kilometers) > 100) {
                        self.notyf.error("Število kilometrov mora biti več kot 0 in manj kot 100!");
                        return;
                    } 

                    var request = {
                        date: date,
                        kilometers: kilometers
                    };

                    self._ridesApiClient.addOrUpdateRides(request)
                        .then(() => {
                            formElement.find("[name=kilometers]").val('');
                            self.notyf.success("Vnos je bil uspešen!");
                        })
                        .catch(() => {
                            self.notyf.error("Prišlo je do napake");
                        })
                        .finally(async () => {
                            $("#rideModal").modal("hide");
                            self.loader.hide();
                            await self._renderData();
                        });
                });

                // hide modal on button Prekli�i
                $("#cancelRide").on("click", function () {
                    $("#rideModal").modal('hide');
                });

                $("#rideModal").modal("show");
                formElement.find("#kilometers").focus().val(kilometers);
            });
        });

        /* Delete inputed ride */
        $(".deleteRide").on("click", function (e) {
            if ($(e.target).closest(".calendar-date").hasClass("disabled")) {
                return;
            }
            var id: any = e.target.getAttribute("value");
            const modalHtml = Mustache.render(deleteRideModal, null);
            $('#deleteRideModalWrap').html(modalHtml);

            $("#confirmRideDelete").on("click", function () {
                //self.loader.show();
                self._ridesApiClient.delete(id)
                    .then(() => {
                        self.notyf.success("Vnos je bil izbrisan!");
                    })
                    .catch(() => {
                        self.notyf.error("Prišlo je do napake");
                    })
                    .finally(async () => {
                        $("#rideDelete").modal("hide");
                        self.loader.hide();
                        await self._renderData();
                    });
            });

            $("#cancelRideDelete").on("click", function () {
                $("#rideDelete").modal('hide');
            });

            $("#rideDelete").modal('show');
        });
    }

    public getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'sl';
        moment.locale(userLocale);
        return {
            dayFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('dddd');
                };
            },
            shortDateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('D.M.');
                };
            },
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('D.M.Y');
                };
            },
            fullDateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('yyyy-MM-DD');
                };
            },
            decimalFormat: function () {
                return function (timestamp: any, render: any) {
                    var decimal = render(timestamp).trim() as number;
                    return Number(decimal).toLocaleString('sl-SI');
                };
            }
        };
    }

    private _dodajValidacijskeFunkcije(): void {
        $.validator.addMethod("numberIsValid", function (number) {
            number = number.trimStart();
            number = number.replace(',', '.');
            var check = Number(number);
            if (isNaN(check)) {
                return false;
            }
            return true;
        });
    }

    private async _addBikerInfoForm(ev: Event, formElement: any) {
        ev.preventDefault();
        var formData = new FormData(formElement[0] as HTMLFormElement);
        var commuteDaysAWeek = Number(formData.get("commuteDaysAWeek") as string);
        var commuteFrequency = Number(formData.get("commuteFrequency") as string);
        var distance = formData.get("commuteDistance") as string;
        var commuteDistance = Number(distance.replace(',', '.'));
        var region = formData.get("region") as string;
        var employer = formData.get("employer") as string;

        var data: BikerInfoFormRequest = {
            commuteDaysAWeek: commuteDaysAWeek,
            commuteFrequency: commuteFrequency,
            commuteDistance: commuteDistance,
            region: region,
            employer: employer
        };

        await this._userApiClient.addBikerInfoForm(data)
            .then((odgovor: boolean) => {
                if (odgovor) {
                    this.notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                    $("#bikerInfoModal").modal("hide");
                }
            })
            .catch(() => {
                this.notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    public async _addExistingBikerInfoForm(ev: ExistingBikerInfoFormRequest, event:Event) {
        event.preventDefault();
        await this._userApiClient.addExistingBikerInfoForm(ev)
            .then((odgovor: boolean) => {
                if (odgovor) {
                    // this.notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                    $(".modal-step4").hide();
                    $(".modal-finish").show();

                }
            })
            .catch(() => {
                this.notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    public initializeModal() {
        const newVM = { ...surveyViewModel }; // Spread operator to clone the current viewModel
        const html = Mustache.render(surveyModal, newVM); // Assume you have a script tag with id "surveyModalTemplate"
        $("#surveyModalWrap").html(html);

        $("#surveyModal").modal('show');
        $(".modal-intro").show();
        $(".modal-step1").hide();
        $(".modal-step2").hide();
        $(".modal-step3").hide();
        $(".modal-step4").hide();
        $(".modal-finish").hide();

        var bikerExistingInfoFormElement = $(("#surveyForm"));
        bikerExistingInfoFormElement.on("submit", async (ev: Event) => {
            ev.preventDefault(); // Prevent the default form submission behavior
            if (!bikerExistingInfoFormElement.valid()) {
                return;
            }

            var formDataArray = bikerExistingInfoFormElement.serializeArray();
            const data: Record<string, any> = {};
            formDataArray.forEach((item) => {
              if (data[item.name]) {
                if (!Array.isArray(data[item.name])) {
                  data[item.name] = [data[item.name]];
                }
                data[item.name].push(item.value);
              } else {
                data[item.name] = item.value;
              }
            });
           
            const serializedMaybeUser : ExistingBikerInfoFormRequest = JSON.parse(JSON.stringify(data as ExistingBikerInfoFormRequest));
            await this._addExistingBikerInfoForm(serializedMaybeUser, ev);
        });
    }

    public async cancleExistingForm(ev: Event)
    {
        ev.preventDefault();
        await this._userApiClient.updateShowExistignForm()
            .then((odgovor: boolean) => {
                if (odgovor) {
                    // this.notyf.success(this._translationService.currentTranslations["SaveSuccessful"]);
                    $(".modal-step4").hide();
                    $(".modal-finish").show();

                }
            })
            .catch(() => {
                this.notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }
}
    function closeSurvey() {
        currentStep = 'surveyStep0';
        Object.keys(surveyViewModel).forEach(key =>  
            {
                const stepKey = key as keyof SurveyViewModel;
                surveyViewModel[stepKey] = false;
            });

            $(".modal-intro").show();
            $(".modal-step1").hide();
            $(".modal-step2").hide();
            $(".modal-step3").hide();
            $(".modal-step4").hide();
            $(".modal-finish").hide();

    }
    function moveToNextStep() {
        const nextStepIndex = parseInt(currentStep.replace('surveyStep', '')) + 1;
        const nextStep: keyof SurveyViewModel = `surveyStep${nextStepIndex}` as keyof SurveyViewModel;
        if (nextStep in surveyViewModel) {
            currentStep = nextStep;
            Object.keys(surveyViewModel).forEach(key   =>  
                {
                    const stepKey = key as keyof SurveyViewModel;
                    surveyViewModel[stepKey] = false;
                });
            surveyViewModel[currentStep] = true;
            renderCurrentStep();
        }
    }
    function moveToPreviousStep() {
        const prevStepIndex = parseInt(currentStep.replace('surveyStep', '')) - 1;
        const prevStep: keyof SurveyViewModel = `surveyStep${prevStepIndex}` as keyof SurveyViewModel;
        if (prevStep in surveyViewModel) {
            currentStep = prevStep;
            Object.keys(surveyViewModel).forEach(key   =>  
                {
                    const stepKey = key as keyof SurveyViewModel;
                    surveyViewModel[stepKey] = false;
                });
            surveyViewModel[currentStep] = true;
            renderCurrentStep();
        }
    }
    function renderCurrentStep() {
        if (surveyViewModel.surveyStep0) {
            $(".modal-intro").show();
            $(".modal-step1").hide();
            $(".modal-step2").hide();
            $(".modal-step3").hide();
            $(".modal-step4").hide();
            $(".modal-finish").hide();
        } 
        if (surveyViewModel.surveyStep1) {
            $(".modal-intro").hide();
            $(".modal-step1").show();
            $(".modal-step2").hide();
            $(".modal-step3").hide();
            $(".modal-step4").hide();
            $(".modal-finish").hide();
        }
        if (surveyViewModel.surveyStep2) {
            $(".modal-intro").hide();
            $(".modal-step1").hide();
            $(".modal-step2").show();
            $(".modal-step3").hide();
            $(".modal-step4").hide();
            $(".modal-finish").hide();
        }
        if (surveyViewModel.surveyStep3) {
            $(".modal-intro").hide();
            $(".modal-step1").hide();
            $(".modal-step2").hide();
            $(".modal-step3").show();
            $(".modal-step4").hide();
            $(".modal-finish").hide();
        }
        if (surveyViewModel.surveyStep4) {
            $(".modal-intro").hide();
            $(".modal-step1").hide();
            $(".modal-step2").hide();
            $(".modal-step3").hide();
            $(".modal-step4").show();
            $(".modal-finish").hide();
        }
    }
    